/* ----------------------------------------- */
/*            Original Tailwind + CSS        */
/* ----------------------------------------- */
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Aptos";   /*Can be any text*/
  src: local("Aptos"),
    url("./fonts/Aptos.ttf") format("truetype");
}

.flex {
  flex: 1;
  display: flex;
}

body {
  font-family: Aptos;
  --primary-color: #028482;
  --seconday-color: #005655;
  --faded-color: #8fcdcc;
  --black: #000000;
  --grey: #c7c7c7;
  --lghtGrey: #fafafa;
  --darkGrey: #818181;
  --danger: rgb(219, 74, 74);
}

.b {
  background-color: var(--primary-color);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border-style: none;
  /* width: 10em; */
  height: 3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.b2 {
  color: white;
  border-radius: 5px;
  border-style: none;
  /* width: 10em; */
  height: 3em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
}

.bFlex {
  background-color: var(--primary-color);
  color: white;
  border-radius: 5px;
  border-style: none;
  width: 100%;
  height: 3em;
  padding: 0.5em;
}

.blackButton {
  background-color: var(--black);
}

.fadedButton {
  background-color: var(--faded-color);
}
.greyButton {
  background-color: var(--grey);
}
.secondayButton {
  background-color: var(--seconday-color);
}
.primaryOutlineButton {
  background-color: #00000000;
  color: #216160;
  border: #216160;
  border-width: 1px;
  border-style: solid;
}

.greyText {
  color: var(--darkGrey);
}

/* Date--ere*/
.date {
  align-items: flex-start;
  /* margin-top: -15px; */
  /* margin-bottom: 15px; */
  width: 100%;
  flex: 1;
  max-width: 18em;
}

.date-label {
  color: #028482;
  margin-bottom: 8px;
}

.date-container {
  background-color: white;
  /* border-radius: 5px; */
  display: flex;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 2px 2px 6px 0px #d6d6d6;
  height: 3em;
}
.date-item {
  padding-top: 0.6em;
  /* padding-bottom: 0.2em; */
  padding-left: 0.4em;
  padding-right: 0.4em;
}
/* --------------------*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  color: white;
}

.form-group {
  margin-bottom: 1em;
  flex-grow: 1;
  /* align-items: center; */
}

.form-group input {
  /* border-radius: 30em; */
  /* padding-left: 2em; */
  border-width: 0;
  box-shadow: 2px 2px 6px 0px #d6d6d6;
  height: 3em;
  padding-left: 1.5em;
  /* lef */
}

.inputField {
  display: flex;
}

.form-group textarea {
  /* padding-left: 2em; */
  height: 5em;
  border-width: 0;
  box-shadow: 2px 2px 6px 0px #d6d6d6;
}

.form-group select {
  border-width: 0;
  box-shadow: 2px 2px 6px 0px #d6d6d6;
  height: 3em;
  /* padding-left: 1.5em; */
  /* padding-left: 2em; */
}

/* login styles */

.button {
  background-color: #028482;
  border-radius: 5px;
  color: white;
  border-style: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  width: 8em;
  height: 3em;
}

.buttonRow {
  display: flex;
  flex: 1;
  justify-content: center;
}
.loginContainer {
  display: flex;
  min-height: 100vh;
  /* background-color: #ccdfe0; */
  /* position: absolute;
  width: 100%;
  height: 100%; */
}
.forgotPass_text {
  margin-top: 1em;
  color: #000000;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  /* transform: "translate(-50%, -50%)"; */
}

.cScontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 3em;
}
.imageCon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.display-1 {
  color: #028482;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 15em;
}

.box-input {
  margin-top: 3em;
  min-width: 20em;
  width: 40%;
  border: transparent;
}

.centerBox {
  /* flex :1; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1em;
  /* background-color: #000000; */
}

/* ------End-------------*/

/* side navigation styles */

.sideBar-Container {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: stretch;
  /* min-height: 100vh; */
  background-color: #fafafa;
  flex-grow: 0;
}

.sideBar-Sections {
  margin: 2em;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-grow: 1;
}

.light-text {
  color: white;
}

.primary-text {
  color: #216160;
}

.danger-text {
  color: rgb(219, 74, 74);
}

.sideBar-Sections-UserInfo {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  align-items: center;
}

.sideBar-Sections-Nav {
  margin-top: 3em;
  display: flex;
  font-size: normal;

  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}
.nav-item {
  text-decoration: none;
}

.sideBar-Sections-Nav-Item {
  margin-top: 3em;
  display: flex;
  align-items: center;
  /* flex-direction: row; */
  /* align-items: center; */
}
.rightspcae-2 {
  padding-left: 1em;
}

.paddingLeft1 {
  padding-left: 0.5em;
}

/* Top bar navigation */

.topBar-Container {
  background-color: #fafafa;
  margin: 1em 1em 0.5em 1em;
  border-radius: 10px;
  box-shadow: 2px 2px 6px 0px #d6d6d6;
}

.topBar-Sections {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
  /* background-color: #028482; */
}

.topBar-Sections-1 {
  display: flex;
  flex-direction: row;
  padding-left: 3em;
}
.Center-Name {
  padding-left: 1em;
}
.topBar-Sections-2 {
  display: flex;
  flex-direction: row;
  padding-right: 3em;
  /* background-color: #028482; */
}
.topBar-Sections-2-profileBox {
  align-items: end;
  margin-right: 2em;
  /* background-color: #028482; */
  /* background-color: #028482; */
}
.topBar-Sections-2-profileText {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 5vw !important;
  /* flex-grow: 1; */
  /* background-color: #028482; */
}

.user-profile-box {
  background-color: #028482;
  height: 3.5em;
  width: 3.5em;
  /* flex: 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100em;
  margin-right: 1em;
}
.user-profile-box-res {
  background-color: #028482;
  height: 4.5em;
  width: 4.5em;
  /* flex: 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 1em;
}

.topBar-Sections-2-Logout {
  /* background-color: #028482; */
  flex: 1;
  display: flex;
  flex-direction: row;
  /* justify-content: flex-end; */
  align-items: center;
}

/* ------End-------------*/

/* dashboard styles */

.dashboard {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: stretch;
  /* width : 20vh */
  /* background-color: #ccdfe0; */
}

.dashboard-sideBar {
  display: flex;
  min-width: 20vh;
  /* flex-grow: 1 */
}

.dashboard-main-routes {
  display: flex;
  min-height: 100vh;
  /* width : ; */
  flex-grow: 12;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  /* opacity: 0.5; */
}

.dashboard-main-Scrollable {
  overflow-y: auto;
  height: auto;
  /* background-color: #000000; */
}

/* ------End-------------*/

/* find styles */

.findResident-Container {
  padding: 1em;
  /* display: flex; */
  flex-direction: column;
  /* flex-grow: 1; */
  /* background-color: #ccdfe0; */
}

.findResident-scrollable-container {
  /* flex :1 */
  /* background-color: #ccdfe0; */
}

.findResident-scrollable {
  /* flex-grow: 1; */
  max-height: 65vh;
  overflow-y: scroll;
}

.findResident-Container-searchSection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
.findResident-Container-searchSection-item {
  /* padding: 0.5em; */
  flex-grow: 1;
  flex: 1;
  display: flex;
}

.findResidentButtons {
  flex: 1;
  display: flex;
  flex-grow: 2;
  padding-left: 1em;
  margin-bottom: 1em;
}

.filterDropdown {
  padding-left: 1em;
  margin-bottom: 1em;
}

.findResident-Container-resultSection {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.findResident-Container-resultSection-Action {
  padding-top: 4em;
  padding-bottom: 4em;
  padding-left: 1em;
  padding-right: 1em;
  margin-top: 10em;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.findResident-Container-resultSection-Action-cases {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.findResident-Container-resultSection-Action-Found-text {
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  /* padding-left: 2em; */
  align-items: center;
}

.findResident-Container-resultSection-Action-Found-userFound-b {
  margin-left: 2em;
  padding-left: 2em;
  padding-right: 2em;
}

.findResident-Container-data {
  /* padding: 1em; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.findResident-Container-data-Item {
  margin-top: 0.8em;
  padding: 1em;
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 2em;
  padding-left: 2em;
  flex-direction: row;
  /* background-color: white; */
  height: 70px;
  justify-content: space-between;
  /* box-shadow: 2px 2px 6px 0px #d6d6d6; */
  background-color: #fafafa;
}
.findResident-Container-data-header {
  margin-top: 0.8em;
  /* padding: 1em; */
  display: flex;
  flex: 1;
  align-items: center;
  padding-right: 2em;
  padding-left: 2em;
  flex-direction: row;
  height: 30px;
  justify-content: space-between;
  font-size: larger;
  /* background-color: var(--primary-color);
  color : white */
}

.findResident-Container-data-Item-ind {
  flex: 1;
  min-width: 9.5rem !important;

  /* background-color: "Red"; */
  /* max-width: 15em; */
}
.findResident-Container-data-Item-ind-item {
  font-size: larger;
}
.resident-button {
  background-color: #028482;
  border-radius: 100px;
  color: white;
  border-style: none;
  width: 10em;
  height: 2em;
}

.update-button {
  border-width: 2px;
  border-color: black;
  border-radius: 100px;
  color: black;
  width: 10em;
  height: 2em;
  background-color: white;
}
.exit-button {
  background-color: #000000;
  border-radius: 100px;
  color: white;
  border-style: none;
  width: 10em;
  height: 2em;
}
.flexEnd {
  justify-content: flex-end;
  align-items: flex-end;
}
.primary {
  color: #028482;
}

.leftPadding {
  padding-left: 1em;
}

.leftMargin {
  margin-left: 1em;
}

/* ------End-------------*/

/* createResident Message styles */

.Submitting-message {
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  align-items: center;
  justify-content: flex-start;
  /* max-width: 600px; */
  margin-top: 200px;
  padding-bottom: 100px;
}

/* ------End-------------*/

/* createResident styles */

.createResident-Container {
  padding: 3em;
  padding-top: 2em;
  /* background-color: #ccdfe0; */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.createResident-Container-headSection {
  display: flex;
  max-width: 1300px;
  min-width: 1000px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.createResident-progressContainer {
  width: 8em;
}

.CreateForm-Session-Counter {
  background-color: #028482;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  padding-right: 3em;
  padding-left: 3em;
}

.createResident-Container-formSection {
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.createResident-Container-formSection-row {
  display: flex;
  column-gap: 2em;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 86px;
  flex: 1;
  /* padding-left: 1em; */
  /* align-items: center; */
  /* justify-content: space-around; */
  /* background-color: lightgreen; */
}

.createResident-Container-formSection-rowItem {
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
}
.createResident-Container-formSection-rowItem-end {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.createResident-Container-formSection-rowItem-center {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.createResident-Container-formSection-rowItem-pagination {
  display: flex;
  align-items: center;
}
.createResident-Container-endSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.createResident-Container-formSection-rowItem-nextButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  /* margin-right: 10em; */
}

.formSection-rowItem-nextButton {
  margin-left: 1em;
  margin-right: 1em;
  width: 170px;
}
.form-pagination {
  margin-top: 1em;
}

.inputLabel {
  padding-bottom: 0.5em;
  color: #028482;
}

.form-check {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
}

.form-check-input {
  padding: 15px;
}

.form-check-label {
  padding-left: 1em;
  padding-top: 0.4em;
  color: #028482;
}

.custom-form-check {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
}

.uplabel-form-check {
  display: flex !important;
  flex-direction: column-reverse !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
}

.custom-form-check > input {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.checkbox {
  min-width: 100px;
}

/* ------End-------------*/

/* updateResident styles */

.updateResident-Container {
  padding: 1em;
  padding-top: 3em;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* background-color: #ccdfe0; */
}

.updateResident-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
}

/* ------End-------------*/

/* exitResident styles */

.exitResident-Container {
  padding: 1em;
  padding-top: 3em;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* background-color: #ccdfe0; */
  align-items: center;
}

.exitResident-FormContainer {
  min-width: 1000px;
}

.updateResident-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
}

/* ------End-------------*/

/* reports styles */

.reports-Container {
  padding: 1em;
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.reports-Container-Section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.report-button {
  margin-left: 1em;
  margin-right: 1em;
  width: 170px;
}
.reports-csv-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.reports-Container-Section-Individual {
  display: flex;
  background-color: var(--lghtGrey);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  flex-grow: 1;
  margin: 0.3rem;
  border-radius: 1em;
}

.reports-Container-Section-Individual-Text {
  padding-bottom: 1.5em;
}
.reports-Container-Section-Individual-Dates {
  display: flex;
  justify-content: space-between;
  width: 37rem;
  padding-bottom: 2em;
}

/* ------End-------------*/

/* ------Checkin View-------------*/

.checkin-Container-Section {
  display: flex;
  flex-direction: row;
  max-width: 1300px;
  /* min-width: 1000px; */
  justify-content: space-between;
  gap: 2em;
}

.checkin-Container-Section-Individual {
  display: flex;
  background-color: var(--lghtGrey);
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 3em;
  flex: 1;
  /* margin: 0.6em; */
  border-radius: 1em;
}

.checkin-HeadTextContainer {
  display: flex;
  gap: 1em;
}

.checkin-Container-headSection {
  display: flex;
  max-width: 1300px;
  /* min-width: 1000px; */
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.checkin-Contianer-Dropdown {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 1em;
  max-width: 40%;
}
.justifyCenter {
  justify-content: center;
}

.checkin-button {
  width: 170px;
}

/* ------End-------------*/

/* ------Toggle Resident View-------------*/

.toggle-Container {
  display: flex;
  width: 100%;
}

.toggle-List {
  flex: 1;
  overflow-y: auto;
  /* max-height: 30vh; */
  /* min-height: 25vh; */
  margin: -1em;
  margin-top: 1em;
}

.toggle-buttonContainer {
  display: flex;
  gap: 2em;
}

/* ------End-------------*/
/* ------REsident View-------------*/

.residentView-Container {
  padding: 2em;
  /* padding-top: 3em; */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.residentView-Header {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 2em;
  margin: 1em;
  margin-bottom: 2em;
  /* height  :1em */
}

.residentView-activeBadge {
  height: 2.7em;
  width: 9em;
  background-color: var(--seconday-color);
  color: #fafafa;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 30em;
}

.residentView-DangerBadge {
  height: 2.7em;
  /* width : 9em; */
  background-color: var(--danger);
  color: #fafafa;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 1.5em;
  padding-right: 1.5em;
  border-radius: 30em;
}
.residentView-Sections {
  display: flex;
  flex-wrap: wrap;
  /* flex :1; */
  /* flex-grow: 1; */
}
.residentView-sectionBox {
  margin: 1em;
  box-shadow: 2px 2px 6px 0px #d6d6d6;
  flex: 1;
  /* height: 40em; */
  border-radius: 10px;
  padding: 1.5em;
  padding-top: 2em;
  padding-bottom: 2em;
}

.residentView-sectionBox-Placeholder {
  margin: 1em;
  /* box-shadow: 2px 2px 6px 0px #d6d6d6; */
  flex: 1;
  height: fit-content;
  width: fit-content;
  /* max-width: fit-content;*/
  min-width: 60vh;
  border-radius: 10px;
  padding: 1.5em;
  padding-top: 2em;
  padding-bottom: 2em;
}

.flex-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
}

.profileView-sectionBox {
  margin: 1em;
  box-shadow: 2px 2px 6px 0px #d6d6d6;
  flex: 1;
  height: fit-content;
  width: fit-content;
  /* max-width: fit-content;*/
  min-width: 60vh;
  max-width: 80vh;
  border-radius: 10px;
  padding: 1.5em;
  padding-top: 2em;
  padding-bottom: 2em;
}

.residentView-sectionBox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.residentView-SubTitle {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1em;
  margin-top: 3em;
}

/* ------End-------------*/

/* ------MultiSection-------------*/
.multiItem-container {
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* padding-top: 2em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: center; */
}

.multiItem-container-Items {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50px;
  /* max-width: 800px; */
  min-width: 900px;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  flex-direction: column;
}

.noItems {
  padding-top: 4em;
  padding-bottom: 4em;
  color: lightslategray;
}

.dataItems-Container {
  display: flex;
  border-radius: 50px;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #028482;
  min-width: 750px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 2em;
  padding-right: 0.5em;
  margin-bottom: 1em;
}

.cancelbutton {
  background-color: #028482;
  border-radius: 100px;
  color: white;
  border-style: none;
  padding-bottom: 0.4em;
  margin-left: 0.4em;
  margin-right: 0.4em;
  width: 35px;
}

/* ------Resident not found------------*/

.Resident-NotFound-container {
  margin-top: 10em;
  background-color: var(--lghtGrey);
  height: 18em;
  width: 18em;
  padding: 2em;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

/* ------Image Picker-------------*/

.ImagePicker-Box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* min-width: 20em; */
  /* flex-direction: co; */
}

.ImagePicker-Box-Edit {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0.3em;
  /* min-width: 20em; */
  /* flex-direction: co; */
}

/* ------Yes No Button-------------*/
.yesNo-container {
  display: flex;
  min-width: 220px;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
  flex-direction: column;
}

.yesNo-container-options {
  display: flex;
}

.yesNo-container-options-item-unselected {
  box-shadow: 2px 2px 6px 0px #d6d6d6;
  display: flex;
  background-color: white;
  /* padding-left: 1em; */
  width: 5em;
  height: 3em;
  justify-content: center;
  align-items: center;
  color: grey;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5em;
}

.yesNo-container-options-item-selected {
  box-shadow: 2px 2px 6px 0px #d6d6d6;
  display: flex;
  background-color: #028482;
  /* padding-left: 1em; */
  width: 5em;
  height: 3em;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5em;
}

/* ------End-------------*/

/* -------Serach Resident---------*/

.searchResident-Container {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.searchResident-Container-searchSection {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.searchResident-Container-searchSection-searchBox {
  width: 20em;
}

.searchResident-Container-searchSection-searchButton {
  padding-left: 2em;
  padding-bottom: 1em;
}

.searchResident-scrollable {
  /* flex-grow: 1; */
  max-height: 70vh;
  width: 80%;
  overflow-y: scroll;
}

.searchResident-Container-data {
  /* padding: 1em; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.searchResident-NotFound-container {
  background-color: var(--lghtGrey);
  height: 18em;
  width: 18em;
  padding: 2em;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

/* ------End-------------*/

/* -------Simple Form ---------*/

.simpleForm-Container-formSection {
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  /* min-width: 1000px; */
  flex-wrap: wrap;
  /* background-color: #ccdfe0; */
  justify-content: flex-start;
}

.simpleForm-Container-endSection {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2em;
}

/* ------End-------------*/

/* -------Notes Section ---------*/
.notesList-Container {
  display: flex;
}

.notesList-List {
  flex: 1;
  overflow-y: auto;
  max-height: 46em;
  margin: -1em;
  margin-top: 1em;
}

.noteList-Item {
  margin: 1em;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;
  min-height: 100px;
  /* max-width: 50px; */
  background-color: var(--lghtGrey);
  box-shadow: 2px 2px 6px 0px #d6d6d6;

  /* margin-bottom : 1em; */
  border-radius: 10px;
}

.noteList-Item-Title {
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  font-weight: 500;
  font-size: larger;
  word-break: break-all;
  hyphens: auto;
}

.noteList-Item-Date-container {
  align-items: flex-end;
}

.notesList-NoData {
  height: 200px;
  margin-top: 2em;
  background-color: var(--lghtGrey);
  border-radius: 10px;
  box-shadow: 2px 2px 6px 0px #d6d6d6;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
/* ------End-------------*/

/* -------Fragments Section ---------*/

.fragmentList-Container {
  display: flex;
}

.fragmentList-List {
  flex: 1;
  overflow-y: auto;
  max-height: 30vh;
  /* min-height: 25vh; */
  margin: -1em;
  margin-top: 1em;
}

.fragmentList-Item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  flex: 1;
  /* max-width: 50px; */
  /* background-color: var(--lghtGrey); */
  box-shadow: 2px 2px 6px 0px #d6d6d6;
  /* margin-bottom : 1em; */
  border-radius: 10px;
}

.fragmentList-Item-NoMargin {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  flex: 1;
  /* max-width: 50px; */
  /* background-color: var(--lghtGrey); */
  box-shadow: 2px 2px 6px 0px #d6d6d6;

  /* margin-bottom : 1em; */
  border-radius: 10px;
}

.fragmentList-Item-Title {
  flex-grow: 1;
  flex-basis: 0;
  word-break: break-all;
  hyphens: auto;
}

.noteList-Item-Date {
  padding-top: 0.4em;
  padding-bottom: 1.5em;
  padding-left: 1em;
  padding-right: 2em;
  font-size:large;
  color: var(--primary-color);
  display: flex;
  justify-content: space-between;
}

.noteList-Item-Body {
  padding-left: 1.5em;
  padding-right: 1em;
  padding-bottom: 1.5em;
  /* margin-top : -0.5em; */
  /* word-break: break-word;  */
  /* word-break: break-all; */
  /* white-space: pre; */
  font-size: larger;
  line-height: 1.7rem;
  /* hyphens: auto; */
  /* white-space: normal; */
}

.fragmentList-NoData {
  height: 200px;
  margin-top: 2em;
  background-color: var(--lghtGrey);
  border-radius: 10px;
  box-shadow: 2px 2px 6px 0px #d6d6d6;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

/* ------End-------------*/

/* -------Phases Section ---------*/

.PhaseManagement-Container {
  display: flex;
  flex-direction: column;
}

.PhaseManagement-currentPhase {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 5em;
  padding-top: 2em;
}

.PhaseManagement-buttons {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 1.5rem;
}

/* -------User Management ---------*/

.UserManagement-FilterSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  padding-top: 2em;
}

.UserManagement-FilterSection-inputs {
  display: flex;
  flex: none;
  flex-grow: 2;
}

.UserManagement-FilterSection-button {
  display: flex;
  flex: 1;
  flex-grow: 2;
  justify-content: flex-end;
  align-items: center;
}

/* ------No data-------------*/

.noDataBox {
  /* display:  flex; */
  background-color: #f7f7f7;
  flex: 1;
  height: 20rem;
  border-radius: 5px;
  border-style: none;
  justify-content: center;
}

.noDataBox-Text {
  /* background-color: #005655; */
  flex: 1;
  display: flex;
  padding: 10rem;
  justify-content: center;
  font-size: xx-large;
}

/* ------End-------------*/

.App-link {
  color: #61dafb;
}

/* Flex grow items */

.center {
  justify-content: center;
}
.bold {
  font-weight: 600;
}

.grow03 {
  flex-grow: 0.3;
}
.grow05 {
  flex-grow: 0.5;
}
.grow1 {
  flex-grow: 1;
}
.grow2 {
  flex-grow: 2;
}
.grow3 {
  flex-grow: 3;
}

.nowrap {
  flex-wrap: nowrap;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}
.center {
  display: flex;
  justify-content: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}

.paddingRight01 {
  padding-right: 1em;
}

.paddingLeft01 {
  padding-left: 1em;
}

.paddingTop01 {
  padding-top: 1em;
}

.marginVertical {
  margin-top: 2em;
  margin-bottom: 2em;
}

.marginBottom {
  margin-bottom: 2em;
}

.paddingVertical {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.fullHeight {
  height: 100%;
}

.backgroundWhite {
  background-color: white;
}

.backgroundLight {
  background-color: var(--lghtGrey);
}

.roundedCorners {
  border-radius: 15px;
}

.alignCenter {
  align-items: center;
}

.row {
  flex-direction: row;
}

button,
::before,
::after {
  border-width: 0 !important;
  border-style: solid;
  border-color: theme("borderColor.DEFAULT", currentColor);
}

h4 {
  font-weight: 550 !important;
}

.form-control:disabled {
  background-color: inherit !important;
  /* opacity: 1; */
}

.phase-list-reverse {
  display: flex;
  flex-flow: column-reverse;
}
