

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .content {
  margin-left: 30px;
  margin-right: 30px;
} */

/* .navbar {
  margin-bottom: 30px;
} */

.clickable {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
